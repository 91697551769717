<template>
  <c-modal
    :is-open="isOpen"
    :close-on-overlay-click="true"
    is-centered
    size="2xl"
    :on-close="close"
  >
    <c-modal-content
      ref="content"
      class="modalss"
      w="100%"
      max-w="340px"
    >
      <c-modal-body p="0">
        <CImage
          src="https://ik.imagekit.io/dietela/pwa_webp/popup/popup_changeconfirmation.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677042871802"
          alt="success"
          pos="relative"
          z-index="1"
          mx="auto"
          mb="20px"
          h="150px"
        />
        <c-text
          font-size="20px"
          font-weight="700"
          font-family="Roboto"
          align="center"
          color="#008C81"
          pb="20px"
        >
          Apa kamu yakin ingin mengubah kata sandi?
        </c-text>
        <c-flex
          flex-direction="row"
          gap="20px"
          w="100%"
          mx="auto"
          align-items="center"
          justify-content="center"
          px="10px"
        >
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#D32737',
              },
            }"
            w="100%"
            max-w="130px"
            h="34px"
            bg="#D32737"
            color="white"
            border-radius="16px"
            px="32px"
            py="7px"
            @click="close"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Batal
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-cancel.svg')"
                alt="toggle password visibility"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
          <c-button
            v-chakra="{
              ':hover': {
                bg: '#008C81',
              },
              ':disabled': {
                opacity: '1 !important',
              },
            }"
            w="100%"
            max-w="130px"
            h="34px"
            bg="#008C81"
            color="white"
            border-radius="16px"
            px="14px"
            py="7px"
            font-family="Roboto"
            :font-size="['18px']"
            :font-weight="['500']"
            @click="confirm"
          >
            <c-flex
              gap="12px"
              align-items="center"
              justify-content="center"
              flex-grow="1"
            >
              <c-text
                font-family="Roboto"
                :font-size="['14px']"
                :font-weight="['500']"
                align="center"
              >
                Konfirmasi
              </c-text>
              <c-image
                :src="require('@/assets/icons/icon-confirm.svg')"
                alt="toggle password visibility"
                :h="'24px'"
                :w="'24px'"
              />
            </c-flex>
          </c-button>
        </c-flex>
      </c-modal-body>
    </c-modal-content>
    <c-modal-overlay />
  </c-modal>
</template>

<script>
import iconHome from '@/assets/ic-home.svg'

export default {
  name: 'ModalConfirmPassword',
  props: {
    isOpen: {
      type: Boolean,
    },
  },
  data() {
    return {
      iconHome,
    }
  },
  methods: {
    close() {
      this.$emit('handle-close')
    },
    confirm() {
      this.$emit('updatePassword')
    },
  },
}
</script>
<style scoped>
.modalss::v-deep section {
  border-radius: 16px;
  margin: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 30px 20px;
}
</style>