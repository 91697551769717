var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-modal', {
    attrs: {
      "is-open": _vm.isOpen,
      "close-on-overlay-click": true,
      "is-centered": "",
      "size": "2xl",
      "on-close": _vm.close
    }
  }, [_c('c-modal-content', {
    ref: "content",
    staticClass: "modalss",
    attrs: {
      "w": "100%",
      "max-w": "340px"
    }
  }, [_c('c-modal-body', {
    attrs: {
      "p": "0"
    }
  }, [_c('CImage', {
    attrs: {
      "src": "https://ik.imagekit.io/dietela/pwa_webp/popup/popup_changeconfirmation.webp?ik-sdk-version=javascript-1.4.3&updatedAt=1677042871802",
      "alt": "success",
      "pos": "relative",
      "z-index": "1",
      "mx": "auto",
      "mb": "20px",
      "h": "150px"
    }
  }), _c('c-text', {
    attrs: {
      "font-size": "20px",
      "font-weight": "700",
      "font-family": "Roboto",
      "align": "center",
      "color": "#008C81",
      "pb": "20px"
    }
  }, [_vm._v(" Apa kamu yakin ingin mengubah kata sandi? ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "20px",
      "w": "100%",
      "mx": "auto",
      "align-items": "center",
      "justify-content": "center",
      "px": "10px"
    }
  }, [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#D32737'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#D32737',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "bg": "#D32737",
      "color": "white",
      "border-radius": "16px",
      "px": "32px",
      "py": "7px"
    },
    on: {
      "click": _vm.close
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Batal ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-cancel.svg'),
      "alt": "toggle password visibility",
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#008C81'
        },
        ':disabled': {
          opacity: '1 !important'
        }
      },
      expression: "{\n            ':hover': {\n              bg: '#008C81',\n            },\n            ':disabled': {\n              opacity: '1 !important',\n            },\n          }"
    }],
    attrs: {
      "w": "100%",
      "max-w": "130px",
      "h": "34px",
      "bg": "#008C81",
      "color": "white",
      "border-radius": "16px",
      "px": "14px",
      "py": "7px",
      "font-family": "Roboto",
      "font-size": ['18px'],
      "font-weight": ['500']
    },
    on: {
      "click": _vm.confirm
    }
  }, [_c('c-flex', {
    attrs: {
      "gap": "12px",
      "align-items": "center",
      "justify-content": "center",
      "flex-grow": "1"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "align": "center"
    }
  }, [_vm._v(" Konfirmasi ")]), _c('c-image', {
    attrs: {
      "src": require('@/assets/icons/icon-confirm.svg'),
      "alt": "toggle password visibility",
      "h": '24px',
      "w": '24px'
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-modal-overlay')], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }