var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "mx": "auto",
      "min-h": ['calc(100vh - 62px)', 'auto'],
      "bg": ['#FFF', '#FFF'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "p": ['1rem', '1.5rem'],
      "min-height": ['unset', '74vh']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumb
    }
  })], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "bg": "white",
      "p": ['10px', '0'],
      "mx": [null, 'auto'],
      "gap": ['10px', '16px'],
      "border-radius": "12px",
      "max-w": "540px",
      "w": "100%"
    }
  }, [_c('c-text', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['28px'],
      "font-weight": ['700'],
      "display": ['none', 'block'],
      "pb": "24px"
    }
  }, [_vm._v(" Ubah Password ")]), _c('c-form-control', {
    attrs: {
      "is-required": _vm.isGoogle === 1 ? false : true,
      "is-invalid": _vm.$v.oldPassword.$invalid,
      "max-w": "540px",
      "display": _vm.isGoogle === 1 ? 'none' : 'block'
    }
  }, [_c('c-form-label', {
    attrs: {
      "px": "20px",
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": ['400'],
      "color": _vm.$v.oldPassword.$invalid ? 'black' : '#008C81'
    }
  }, [_vm._v(" Kata Sandi Lama ")]), _c('c-input-group', [_c('c-input-left-element', [_c('c-image', {
    attrs: {
      "variant": "ghost",
      "top": "50%",
      "transform": ['translateY(10%)', 'translateY(30%)'],
      "src": _vm.$v.oldPassword.$invalid ? require('@/assets/icons/icon-lock-grey.svg') : require('@/assets/icons/icon-lock-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px'],
      "ml": ['20px']
    }
  })], 1), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'none !important',\n            },\n          }"
    }],
    attrs: {
      "type": _vm.shownPassword.includes('oldPassword') ? "text" : "password",
      "placeholder": "Masukkan Password Lama",
      "h": ['48px', '62px'],
      "tabindex": "1",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": ['500'],
      "border-radius": "8px",
      "color": _vm.$v.oldPassword.$invalid ? 'black' : '#008C81',
      "border": _vm.$v.oldPassword.$invalid ? '1px solid #888888' : '1px solid #008C81',
      "w": "100%"
    },
    model: {
      value: _vm.oldPassword,
      callback: function callback($$v) {
        _vm.oldPassword = $$v;
      },
      expression: "oldPassword"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n              ':focus': {\n                outline: 'none',\n                boxShadow: 'none !important',\n                background: 'none !important',\n              },\n            }"
    }],
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": ['translateY(-40%)', 'translateY(-20%)']
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('oldPassword');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.$v.oldPassword.$invalid ? require('@/assets/icons/icon-show-password-grey.svg') : require('@/assets/icons/icon-show-password-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px']
    }
  })], 1)], 1)], 1)], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.newPassword.$invalid,
      "max-w": "540px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "px": "20px",
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": ['400'],
      "color": _vm.$v.newPassword.$invalid ? 'black' : '#008C81'
    }
  }, [_vm._v(" Kata Sandi Baru ")]), _c('c-input-group', [_c('c-input-left-element', [_c('c-image', {
    attrs: {
      "variant": "ghost",
      "top": "50%",
      "transform": ['translateY(10%)', 'translateY(30%)'],
      "src": _vm.$v.newPassword.$invalid ? require('@/assets/icons/icon-lock-grey.svg') : require('@/assets/icons/icon-lock-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px'],
      "ml": ['20px']
    }
  })], 1), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'none !important',\n            },\n          }"
    }],
    attrs: {
      "type": _vm.shownPassword.includes('newPassword') ? "text" : "password",
      "placeholder": "Masukkan Password Baru",
      "h": ['48px', '62px'],
      "max-w": "540px",
      "tabindex": "1",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": ['500'],
      "border-radius": "8px",
      "color": _vm.$v.newPassword.$invalid ? 'black' : '#008C81',
      "border": _vm.$v.newPassword.$invalid ? '1px solid #888888' : '1px solid #008C81'
    },
    model: {
      value: _vm.newPassword,
      callback: function callback($$v) {
        _vm.newPassword = $$v;
      },
      expression: "newPassword"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n              ':focus': {\n                outline: 'none',\n                boxShadow: 'none !important',\n                background: 'none !important',\n              },\n            }"
    }],
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": ['translateY(-40%)', 'translateY(-20%)']
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('newPassword');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.$v.newPassword.$invalid ? require('@/assets/icons/icon-show-password-grey.svg') : require('@/assets/icons/icon-show-password-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px']
    }
  })], 1)], 1)], 1), !_vm.$v.newPassword.minLength ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password minimal 8 karakter ")]) : _vm._e(), !_vm.$v.newPassword.hasNumber ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung angka ")]) : _vm._e(), !_vm.$v.newPassword.hasLowercaseLetter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung huruf kecil ")]) : _vm._e(), !_vm.$v.newPassword.hasUppercaseLetter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung huruf kapital ")]) : _vm._e(), !_vm.$v.newPassword.hasSpecialCharacter ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Password harus mengandung karakter spesial @$!%()*?& ")]) : _vm._e(), !_vm.$v.newPassword.sameAs ? _c('c-form-helper-text', {
    attrs: {
      "color": "red.500"
    }
  }, [_vm._v(" Kata sandi baru tidak boleh sama dengan kata sandi sebelumnya ")]) : _vm._e()], 1), _c('c-form-control', {
    attrs: {
      "is-required": "",
      "is-invalid": _vm.$v.newPasswordConfirmation.$invalid,
      "max-w": "540px"
    }
  }, [_c('c-form-label', {
    attrs: {
      "px": "20px",
      "font-family": "Roboto",
      "font-size": ['14px', '16px'],
      "font-weight": ['400'],
      "color": _vm.$v.newPasswordConfirmation.$invalid ? 'black' : '#008C81'
    }
  }, [_vm._v(" Ulangi Kata Sandi Baru ")]), _c('c-input-group', [_c('c-input-left-element', [_c('c-image', {
    attrs: {
      "variant": "ghost",
      "top": "50%",
      "transform": ['translateY(10%)', 'translateY(30%)'],
      "src": _vm.$v.newPasswordConfirmation.$invalid ? require('@/assets/icons/icon-lock-grey.svg') : require('@/assets/icons/icon-lock-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px'],
      "ml": ['20px']
    }
  })], 1), _c('c-input', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n            ':focus': {\n              outline: 'none',\n              boxShadow: 'none !important',\n              background: 'none !important',\n            },\n          }"
    }],
    attrs: {
      "type": _vm.shownPassword.includes('newPasswordConfirmation') ? "text" : "password",
      "placeholder": "Ulangi Password Baru",
      "h": ['48px', '62px'],
      "max-w": "540px",
      "tabindex": "1",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": ['500'],
      "border-radius": "8px",
      "color": _vm.$v.newPasswordConfirmation.$invalid ? 'black' : '#008C81',
      "border": _vm.$v.newPasswordConfirmation.$invalid ? '1px solid #888888' : '1px solid #008C81'
    },
    model: {
      value: _vm.newPasswordConfirmation,
      callback: function callback($$v) {
        _vm.newPasswordConfirmation = $$v;
      },
      expression: "newPasswordConfirmation"
    }
  }), _c('c-input-right-element', [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':focus': {
          outline: 'none',
          boxShadow: 'none !important',
          background: 'none !important'
        }
      },
      expression: "{\n              ':focus': {\n                outline: 'none',\n                boxShadow: 'none !important',\n                background: 'none !important',\n              },\n            }"
    }],
    attrs: {
      "variant": "ghost",
      "p": "0",
      "m": "0",
      "top": "50%",
      "right": "10px",
      "transform": ['translateY(-40%)', 'translateY(-20%)']
    },
    on: {
      "click": function click($event) {
        return _vm.togglePasswordVisibility('newPasswordConfirmation');
      }
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.$v.newPasswordConfirmation.$invalid ? require('@/assets/icons/icon-show-password-grey.svg') : require('@/assets/icons/icon-show-password-green.svg'),
      "alt": "toggle password visibility",
      "h": ['20px', '30px'],
      "w": ['20px', '30px']
    }
  })], 1)], 1)], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "flex-direction": "row",
      "gap": "16px",
      "display": ['none', 'flex'],
      "max-w": "540px",
      "w": "100%",
      "mx": "auto",
      "mt": "40px"
    }
  }, [_c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#C4C4C4'
        }
      },
      expression: "{\n        ':hover': {\n          bg: '#C4C4C4',\n        },\n      }"
    }],
    attrs: {
      "w": "100%",
      "h": "70px",
      "bg": "#C4C4C4",
      "color": "white",
      "border-radius": "58px",
      "p": "10px 50px",
      "font-family": "Roboto",
      "font-size": ['18px'],
      "font-weight": ['500']
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push('/');
      }
    }
  }, [_vm._v(" Batal ")]), _c('c-button', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':hover': {
          bg: '#008C81'
        },
        ':disabled': {
          opacity: '1 !important'
        }
      },
      expression: "{\n        ':hover': {\n          bg: '#008C81',\n        },\n        ':disabled': {\n          opacity: '1 !important',\n        },\n      }"
    }],
    attrs: {
      "disabled": _vm.$v.$invalid,
      "w": "100%",
      "h": "70px",
      "bg": "#008C81",
      "color": "white",
      "border-radius": "58px",
      "font-family": "Roboto",
      "font-size": ['18px'],
      "font-weight": ['500'],
      "p": "10px 50px"
    },
    on: {
      "click": _vm.openModalConfirm
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-flex', {
    attrs: {
      "px": "20px",
      "mx": "-20px",
      "bottom": "40px",
      "w": "100%",
      "pos": "fixed",
      "display": ['block', 'none']
    }
  }, [_c('c-button', {
    attrs: {
      "disabled": _vm.$v.$invalid,
      "w": "100%",
      "h": "50px",
      "bg": _vm.$v.$invalid ? '#C4C4C4' : '#008C81',
      "color": "white",
      "border-radius": "58px",
      "font-family": "Roboto",
      "font-size": ['14px'],
      "font-weight": ['500'],
      "p": "10px 50px"
    },
    on: {
      "click": _vm.openModalConfirm
    }
  }, [_vm._v(" Simpan ")])], 1), _c('ModalConfirmPassword', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isConfirm,
      expression: "isConfirm"
    }],
    attrs: {
      "is-open": _vm.isConfirm
    },
    on: {
      "handle-close": _vm.handleCloseConfirmModal,
      "updatePassword": _vm.updatePassword
    }
  }), _c('ModalSuccessPassword', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isSucceeded,
      expression: "isSucceeded"
    }],
    attrs: {
      "is-open": _vm.isSucceeded
    },
    on: {
      "handle-close": _vm.handleCloseSuccessModal
    }
  }), _c('ModalErrorPassword', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.isError,
      expression: "isError"
    }],
    attrs: {
      "is-open": _vm.isError
    },
    on: {
      "handle-close": _vm.handleCloseErrorModal
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }